import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app-root/services/auth.service';

@Injectable()
export class LoginHttpInterceptor implements HttpInterceptor {
  public nones?: string | null;

  public constructor(private authService: AuthService) {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.nones = user.token;
      }
    });
  }

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    console.log('richardo');
    if (this.nones) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this.nones),
      });
    }
    return next.handle(req);
  }
}
