import { Injectable } from '@angular/core';
import { LoginUser } from '../modules/app/services/account.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly LOCAL_STORAGE_KEY = 'loggedInUser';
  private userSubject: BehaviorSubject<LoginUser | null>; // BehaviorSubject to store user information as an observable stream

  constructor(private localStorageService: LocalStorageService) {
    const storedUser = this.getUserLocalStorage(); // Check if user exists in localStorage
    this.userSubject = new BehaviorSubject<LoginUser | null>(storedUser); // Initialize BehaviorSubject with the user data or null
  }

  // Observable for other components to subscribe to user changes
  public get user$(): Observable<LoginUser | null> {
    return this.userSubject.asObservable(); // Expose the userSubject as an observable for subscription
  }

  // Save user information in localStorage and notify subscribers
  setUserLocalStorage(user: LoginUser | null): void {
    const userData = JSON.stringify(user);
    this.localStorageService.setItem(this.LOCAL_STORAGE_KEY, userData); // Store user data in localStorage
    this.userSubject.next(user); // Notify all subscribers with the new user data
  }

  // Get user information from localStorage (for internal use)
  private getUserLocalStorage(): LoginUser | null {
    const userData = this.localStorageService.getItem(this.LOCAL_STORAGE_KEY);
    if (userData) {
      return JSON.parse(userData);
    }
    return null;
  }

  // Check if the user is logged in by checking if the BehaviorSubject has a non-null value
  isLoggedIn(): boolean {
    return this.userSubject.value !== null; // Check the current value of the BehaviorSubject
  }

  // Clear user session, remove from localStorage and notify subscribers
  clearUserLocalStorage(): void {
    this.localStorageService.remove(this.LOCAL_STORAGE_KEY); // Remove user from localStorage
    this.userSubject.next(null); // Notify all subscribers that the user is logged out (null)
  }
}
